<!--
 * @Author: wangxb 18254141586@163.com
 * @Date: 2023-05-25 10:21:37
 * @LastEditors: wangxb 18254141586@163.com
 * @LastEditTime: 2023-05-25 13:39:33
 * @FilePath: \ui-djz\src\views\Assistant\index.vue
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav></headerNav>
    <div class="graph-content">
      <iframe v-if="canSee"
              :src="chatAiUrl"
              class="graph-content-iframe"></iframe>
    </div>
    <viporlogin bgStr="5"
                :type="viporlogin"
                v-if="!canSee"></viporlogin>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import viporlogin from '@/components/viporlogin'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
export default {
  name: 'graph',
  data() {
    return {
      chatAiUrl: 'https://ai.djz365.com',
      viporlogin: 'login',
      canSee: true
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  components: {
    headerNav,
    viporlogin
  },
  mounted() {
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  methods: {
    authCheck() {
      const authObj = authenticate('aiAssistant', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.graph-content {
  text-align: center;
  &-iframe {
    width: 100vw;
    height: calc(100vh - 94px);
    margin: 0 auto;
  }
}
</style>
